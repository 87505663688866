import gql from "graphql-tag";

export const CREATE_SHIPMENT_COMPLEMENT_MUTATION = gql`
mutation CreateShipmentComplement($concreteShipmentId: Int!, $shipmentMaterials: [ShipmentMaterialParams]!) {
  createShipmentComplement(concreteShipmentId: $concreteShipmentId, shipmentMaterials: $shipmentMaterials) {
    id
    code
  }
}
`;
