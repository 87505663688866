import React from "react";
import ReactSelect from "react-select";
import { withQuery } from "containers/withQuery";
import { FORMULAS_QUERY } from "../../graphql/queries/get/gqlSelectQueries";

const Select = ({
  value,
  onChange,
  concreteFormulas,
}) => (
  concreteFormulas
    ? (
      <div style={{
        width: "100%",
        fontWeight: "bold",
      }}
      >
        <ReactSelect
          className="react-select dark"
          classNamePrefix="react-select"
          name="itemSelected"
          value={value}
          onChange={onChange}
          options={[
            {
              value: "",
              isDisabled: true
            },
            ...concreteFormulas.map(({
              id,
              code,
              fck,
              concreteFamily: {
                slump: {
                  nominal,
                  deviation
                }
              }
            }) => ({
              value: id,
              code,
              label: `Cód. ${code} | FCK ${fck} | Slump ${nominal} ± ${deviation}`,
            }))
          ]}
          placeholder="Escolha..."
        />
      </div>
    ) : null);

export default withQuery({
  query: FORMULAS_QUERY
})(Select);
