import React, { useState, useRef } from "react";
import styled from "styled-components";
import {
  Col,
  Row,
  Input,
} from "reactstrap";
import LoadSlider from "../../components/CustomLoadSlider/CustomLoadSlider";
import Select from "../../components/ConfirmModal/FormulaSelect";
import "./ShipmentCreateModal.css";
import PencilImg from "../../assets/img/pencil-edit-button.svg";

const TooltipContainer = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -10px;
  right: 100%;
  min-width: 200px;
  max-width: 400px;
  padding: 5px 10px;
  background-color: white;
  color: black;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;

  &.show {
    opacity: 1;
  }
`;

const TooltipTrigger = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

const TooltipComponent = ({ children, tooltipText }) => {
  const tooltipRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <TooltipContainer>
      <TooltipTrigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </TooltipTrigger>
      <Tooltip ref={tooltipRef} className={isTooltipVisible ? "show" : ""}>
        {tooltipText}
      </Tooltip>
    </TooltipContainer>
  );
};

const ModalContent = styled.div`
  background-color: #2f4051;
  min-width: 300px;
  padding: 20px;
  border-radius: 10px;
  margin: auto;
  z-index: 2001;
`;

const CloseButton = styled.button`
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const RightCol = styled(Col)`
  text-align: right;
`;

const StartShipmentButton = styled.button`
  background-color: #2484c6;
  color: white;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 4px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;

  &:disabled {
    background-color: #B4B6BB;
    cursor: not-allowed;
    color: black;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: #1D84C6;
  }

  &:disabled:hover {
    background-color: #94969B;
  }
`;

const ModalHeaderText = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 500;
`;

const NumOfBatchesInput = styled(Input)`
  width: 100%;
  height: 40px;
  display: block;
  text-align: right;
  align: right;
  background-color: transparent !important;
  color: #D3D3D3 !important;
  font-size: 20px;
  font-weight: 700;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid #D3D3D3;
  padding-right: 10px;

  &:focus {
    border-bottom: 2px solid #D3D3D3;
    background-color: transparent;
    color: #D3D3D3;
    outline: 0;
  }
`;

const QuestionIcon = styled.div`
  background-color: white;
  color: black;
  font-size: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

export function ControlMenuModal({
  buttonTitle = "INICIAR CARREGAMENTO",
  disableStartShipment,
  formula,
  isEdit,
  numOfBatches,
  quantity,
  setFormula,
  setIsConfirmModalOpen,
  setModalOpen,
  setNumOfBatches,
  setQuantity,
  isMobile,
  isTablet
}) {
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChangeFormula = (selectedFormula) => {
    setFormula(selectedFormula);
  };

  const handleStartShipment = () => {
    setIsConfirmModalOpen(true);
  };

  return (
    <ModalContent
      style={{ width: (isMobile || isTablet ? "60%" : "40%") }}
    >
      <Row
        style={{
          marginBottom: "20px",
          alignItems: "center",
          flexWrap: "noWrap"
        }}
      >
        <Col sm={10}>
          <ModalHeaderText>
            MENU DE CONTROLE
          </ModalHeaderText>
        </Col>
        <Col sm={2} style={{ maxWidth: "60px" }}>
          <CloseButton
            onClick={() => handleCloseModal()}
          >
            <i className="nc-icon nc-simple-remove" />
          </CloseButton>
        </Col>
      </Row>
      <Row
        style={{
          margin: "10px",
          alignItems: "center",
        }}
      >
        <Col>
          <h3
            style={{
              color: "white",
            }}
          >
            Selecione o traço
          </h3>
        </Col>
      </Row>
      <Row
        style={{
          margin: "10px",
          alignItems: "center",
        }}
      >
        <Col>
          <Select
            value={formula}
            onChange={handleChangeFormula}
          />
        </Col>
      </Row>
      <LoadSlider
        quantity={quantity}
        setQuantity={setQuantity}
        lightText
        title="Volume por carga"
        lightBackground={false}
      />
      {
        !isEdit && (
          <Row
            style={{
              alignItems: "center",
              paddingRight: "23px",
              paddingLeft: "23px",
              flexWrap: "noWrap"
            }}
          >
            <Col
              sm={7}
              md={7}
              lg={8}
              padding={0}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{
                    color: "white",
                    fontWeight: 700
                  }}
                >
                  Número de cargas
                </h3>
                <TooltipComponent
                  tooltipText="Número de cargas iguais (mesmo traço e volume) que serão inseridas na programação"
                >
                  <QuestionIcon>
                    ?
                  </QuestionIcon>
                </TooltipComponent>
              </div>
            </Col>
            <Col
              sm={5}
              md={5}
              lg={4}
              style={{
                maxWidth: "140px",
                marginLeft: "auto"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",

                }}
              >
                <NumOfBatchesInput
                  value={parseInt(numOfBatches, 10)}
                  className="number-input"
                  type="number"
                  inputMode="decimal"
                  onChange={(e) => setNumOfBatches(parseInt(e.target.value, 10))}
                  min={1}
                  step={1}
                />
                <img
                  src={PencilImg}
                  alt="edit"
                  style={{ height: "0.7rem" }}
                />
              </div>
            </Col>
          </Row>
        )
      }
      <StartShipmentButton
        disabled={disableStartShipment}
        onClick={() => handleStartShipment()}
      >
        {buttonTitle}
      </StartShipmentButton>
    </ModalContent>
  );
}
