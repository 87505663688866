import React from "react";
import { compose, graphql } from "react-apollo";
import { Button, Modal, Input } from "reactstrap";
import { withQuery } from "containers/withQuery";
import {
  Title,
  Subtitle,
  Label,
  Span
} from "components/StyledComponents";
import { convertArrayToObject } from "utils/convertArrayToObject";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import INVENTORY_ITEMS_LOCATIONS_QUERY from "../graphql/queries/inventoryItemsLocationsQuery";
import FINISH_LOADING_MANUAL_SHIPMENT_MUTATION from "../graphql/mutations/finishLoadingManualShipmentMutation";
import RESOLVE_MANUAL_OPERATION_MUTATION from "../graphql/mutations/resolveManualOperationMutation";
import LOADING_CONCRETE_SHIPMENT from "../graphql/queries/loadingConcreteShipment";

class ManualOprationModal extends React.Component {
  constructor(props) {
    super(props);
    const { inventoryItems } = props;
    const locations = inventoryItems.reduce((acc, cur) => ([
      ...acc,
      ...cur.inventoryLocations.map(({ __typename, ...location }) => ({ ...location, inventoryItemId: cur.id, value: 0 }))
    ]), []);

    this.state = {
      locations: convertArrayToObject(locations, location => location.id),
      reason: "",
    };
  }

  clearState = () => this.setState({ reason: "" });

  onCancel = () => {
    const { clearOperation, refetch } = this.props;

    this.clearState();
    clearOperation();
    refetch();
  }

  onFinish = () => {
    const {
      snackbarContext,
      operation,
      finishLoadingManualShipment,
      resolveManualOperation,
      controllerHasFinished,
    } = this.props;
    const { locations, reason } = this.state;

    if (Object.values(locations).some(location => location.value < 0)) {
      snackbarContext.setNotificationTimeOut(Color.danger, "Não é possível salvar uma carga manual que possua quantidades negativas.");
      return;
    }

    if (reason.trim().length === 0) {
      snackbarContext.setNotificationTimeOut(Color.danger, "É necessário informar o motivo da ação.");
      return;
    }

    const resolveManualOperationParams = {
      reason,
      inventoryChanges: Object.values(locations).map(location => ({
        inventoryLocationId: location.id,
        inventoryItemId: location.inventoryItemId,
        quantity: parseFloat(location.value) * -1,
      }))
    };

    if (operation.concreteShipment !== null) {
      const successMessage = controllerHasFinished
        ? null
        : "Carga manual cadastrada. Aguarde a finalização automática ou cancele em caso de falha.";

      finishLoadingManualShipment({
        variables: {
          concreteShipmentId: operation.concreteShipment.id,
          resolveManualOperationParams
        },
        refetchQueries: [{ query: LOADING_CONCRETE_SHIPMENT }]
      })
        .then(this.onSuccess(successMessage))
        .catch(this.onError);
    } else {
      resolveManualOperation({
        variables: {
          manualOperationId: operation.id,
          resolveManualOperationParams
        },
        refetchQueries: [{ query: LOADING_CONCRETE_SHIPMENT }]
      })
        .then(this.onSuccess("Carga manual cadastrada com sucesso."))
        .catch(this.onError);
    }
  }

  onSuccess = (message) => () => {
    const {
      snackbarContext,
      clearOperation,
      refetch,
      onRefetch,
    } = this.props;

    if (message) {
      snackbarContext.setNotificationTimeOut(Color.success, message);
    }

    this.clearState();
    clearOperation();
    refetch();
    onRefetch();
  }

  onError = error => {
    const {
      snackbarContext,
    } = this.props;

    snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
  }

  onChangeLocation = (locationId) => ({ currentTarget: { value } }) => this.setState(state => ({
    locations: {
      ...state.locations,
      [locationId]: {
        ...state.locations[locationId],
        value
      }
    }
  }));

  onChange = key => ({ currentTarget: { value } }) => this.setState(({ [key]: value }));

  renderTableRows = () => {
    const { inventoryItems } = this.props;
    const { locations } = this.state;

    return inventoryItems.map(item => (
      item.inventoryLocations.map(location => (
        <tr key={location.id}>
          <td>
            <Span>{location.name}</Span>
          </td>
          <td>
            <Span>{item.name}</Span>
          </td>
          <td>
            <Input
              type="number"
              value={locations[location.id].value}
              onChange={this.onChangeLocation(location.id)}
            />
          </td>
        </tr>
      ))
    ));
  }

  render() {
    const { operation, controllerHasFinished } = this.props;
    const { reason } = this.state;

    const title = controllerHasFinished && operation && operation.concreteShipment !== null
      ? "Carregamento finalizado com movimentação fora da automação"
      : "Carga Manual";

    return (
      <Modal
        isOpen={operation !== null}
        size="lg"
        zIndex={2040}
      >
        <div className="modal-header default">
          <Title>{title}</Title>
          <Subtitle>Indique a quantidade total de material utilizado:</Subtitle>
        </div>

        <div className="modal-body default">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th style={{ width: "33%" }}>
                  <Label>LOCAL</Label>
                </th>
                <th style={{ width: "33%" }}>
                  <Label>MATERIAL</Label>
                </th>
                <th style={{ width: "34%" }}>
                  <Label>QUANTIDADE</Label>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.renderTableRows()}
            </tbody>
          </table>

          <Label style={{ marginBottom: 10 }}>MOTIVO DA AÇÃO</Label>
          <Input
            type="textarea"
            value={reason}
            onChange={this.onChange("reason")}
            style={{ padding: "0.375rem 0.75rem" }}
          />
        </div>

        <div className="modal-footer default">
          {
            !controllerHasFinished && (
              <Button className="btn-cancel" onClick={this.onCancel}>
                Cancelar
              </Button>
            )
          }
          <Button className="btn-info" onClick={this.onFinish}>
            Finalizar
          </Button>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withQuery({ query: INVENTORY_ITEMS_LOCATIONS_QUERY }),
  graphql(FINISH_LOADING_MANUAL_SHIPMENT_MUTATION, { name: "finishLoadingManualShipment", }),
  graphql(RESOLVE_MANUAL_OPERATION_MUTATION, { name: "resolveManualOperation", }),
  withSnackbarConsumer,
)(ManualOprationModal);
