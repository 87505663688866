import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Tooltip
} from "reactstrap";
import update from "immutability-helper";

import { withSnackbarConsumer, Color } from "../../contexts/SnackbarContext/SnackbarContext.jsx";
import { convertArrayToObject } from "../../utils/convertArrayToObject";
import {
  ModalCard,
  Box
} from "../StyledComponents";
import "./ConfirmModal.css";

const Container = styled.div`
  padding: 0 30px 30px 30px;
  border-top: "2px solid #F6F8FF";
`;

const MissingWeight = styled.div`
  font-family: "Rubik";
  font-size: 13px;
  font-weight: 500;
  color: #283745;
  padding-top: 10px;
  border-top: 2px solid #fff;
`;

const LocationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const InputLabel = styled.div`
  font-family: "Rubik";
  font-size: 12px;
  font-weight: 500;
  color: #283745;
  margin-bottom: 5px;
`;

const FormGroup = styled.div`
  margin: 10px;
`;

const Icon = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #1D84C6;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${darken(0.2, "#1D84C6")};
  }
`;

class MaterialsLocation extends React.Component {
  constructor(props) {
    super(props);

    const materials = props.materials.map(material => ({
      id: material.id,
      locations: convertArrayToObject(
        Object.values(material.locations).map(({ id }) => ({ id })),
        location => location.id,
        () => ({
          lock: false,
          tooltipOpen: false,
        })
      )
    }));

    this.state = {
      materials: convertArrayToObject(materials, material => material.id),
    };
  }

  toggleLock = (materialId, locationId) => this.setState(state => update(
    state, {
      materials: {
        [materialId]: {
          locations: {
            [locationId]: {
              lock: { $set: !state.materials[materialId].locations[locationId].lock }
            }
          }
        }
      }
    }
  ));

  openTooltip = materialId => this.setState(state => ({
    materials: {
      ...state.materials,
      [materialId]: {
        ...state.materials[materialId],
        tooltipOpen: true
      }
    }
  }));

  closeTooltip = materialId => this.setState(state => ({
    materials: {
      ...state.materials,
      [materialId]: {
        ...state.materials[materialId],
        tooltipOpen: false
      }
    }
  }));

  recalculate = material => {
    const currentLocations = this.state.materials[material.id].locations;
    const unlockeds = Object.values(currentLocations).filter(location => !location.lock);
    const ids = unlockeds.map(location => location.id);

    const lockeds = Object.values(material.locations).filter(location => !ids.includes(location.id));
    const sum = lockeds.reduce(
      (acc, location) => parseFloat(acc) + parseFloat(location.weight || 0),
      0
    );
    const missingWeight = material.formulaWeight - sum;
    if (!ids.length) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        "Não é possível recalcular os pesos se todos os locais estiverem bloqueados."
      );
      return;
    }

    const overWeight = lockeds.find(({ weight }) => weight > material.formulaWeight);
    if (overWeight) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        `${overWeight.name} possui mais que a quantidade necessária.`
      );
      return;
    }

    const insufficientLocation = lockeds.find(({ quantity, weight }) => weight > quantity);
    if (insufficientLocation) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        `${insufficientLocation.name} não possui materiais suficientes em estoque.`
      );
      return;
    }

    const negativeLocation = lockeds.find(({ weight }) => weight < 0);
    if (negativeLocation) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        `${negativeLocation.name} não pode ser menor que 0.`
      );
      return;
    }

    this.props.onRecalculateMaterialRatio(material.id, ids, missingWeight);
  }

  renderMaterialBox = material => {
    const tooltipId = `tooltip${material.id}`;
    const locations = [...Object.values(material.locations)].sort(({ quantity: a }, { quantity: b }) => a - b);
    const currentLocations = this.state.materials[material.id].locations;

    return (
      <Box key={material.id}>
        <ModalCard>
          {`${material.name} - Quantidade total necessária: ${material.formulaWeight} ${material.materialUnit}`}

          <div id={tooltipId}>
            <Icon
              onMouseEnter={() => this.openTooltip(material.id)}
              onMouseLeave={() => this.closeTooltip(material.id)}
              onClick={() => this.recalculate(material)}
            >
              <i className="fas fa-calculator" />
            </Icon>
          </div>
          <Tooltip
            placement="left"
            isOpen={this.state.materials[material.id].tooltipOpen}
            target={tooltipId}
          >
            <p>Clique para recalcular os pesos restantes. Os locais bloqueados não serão afetados.</p>
          </Tooltip>
        </ModalCard>
        <LocationContainer>
          {
            locations.map(location => (
              <FormGroup key={location.id}>
                <InputLabel
                  className={location.weight > location.quantity ? "label-error" : ""}
                >
                  {`${location.name} - Estoque: ${location.quantity} ${material.materialUnit}`}
                </InputLabel>
                <InputGroup size="sm" className={location.weight > location.quantity ? "input-error" : ""}>
                  <Input
                    style={{ width: 100 }}
                    type="number"
                    onChange={({ target: { value } }) => this.props.onChangeMaterialRatio(material.id, location.id, value)}
                    value={location.weight}
                    disabled={currentLocations[location.id].lock}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      {material.materialUnit}
                    </InputGroupText>
                    <InputGroupText onClick={() => this.toggleLock(material.id, location.id)} className="clickable">
                      <i className={`fa fa-${currentLocations[location.id].lock ? "lock" : "unlock"}`} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            ))
          }
        </LocationContainer>
        <MissingWeight className={material.missingWeight < 0 ? "label-error" : ""}>
          {`Quantidade faltante: ${material.missingWeight} ${material.materialUnit} ${material.missingWeight < 0 ? "| quantidade excedida" : ""}`}
        </MissingWeight>
      </Box>
    );
  }

  render() {
    const { materials } = this.props;

    if (!materials.length) {
      return null;
    }

    return (
      <Container>
        <h3 style={{ marginBottom: "19px" }}>Indique o local</h3>

        {materials.map(this.renderMaterialBox)}
      </Container>
    );
  }
}

export default withSnackbarConsumer(MaterialsLocation);
