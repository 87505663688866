import gql from "graphql-tag";

// Time elapsed in hours
export const AVAILABLE_SHIPMENTS_QUERY = gql`
query AvailableConcreteShipments($timeElapsed: Int!, $shipmentType: String!) {
  availableConcreteShipments(timeElapsed: $timeElapsed, shipmentType: $shipmentType) {
    id
    code
    sealNumber
    quantity
    loadingEndTime
    loadingStartTime
    startTime
    shipmentMaterials {
      inventoryItem {
        id
        name
      }
      order
    }
    inventoryChanges {
      id
      quantity
      inventoryItem {
        id
        name
        unit
      }
      inventoryLocation {
        id
        name
      }
    }
    shipmentInfo {
      equipmentPlate
      constructionAddress
      constructionName
      contractCode
    }
    concreteFormula {
      id
      code
      fck
      concreteFamily {
        id
        slump {
          nominal
          deviation
        }
      }
    }
  }
}
`;
