import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import "./ShipmentCreateModal.css";
import ConfirmModal from "components/ConfirmModal/MultipleShipmentModal";
import { ControlMenuModal } from "./ControlMenuModal";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export function ShipmentCreateModal({
  setCreateShipmentModalOpen,
  refetchSequence,
}) {
  const [formula, setFormula] = useState(null);
  const [quantity, setQuantity] = useState(5);
  const [numOfBatches, setNumOfBatches] = useState(1);
  const [disableStartShipment, setDisableStartShipment] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setCreateShipmentModalOpen(false);
  };

  useEffect(() => {
    if (formula && quantity && numOfBatches) {
      setDisableStartShipment(false);
    } else {
      setDisableStartShipment(true);
    }
  }, [formula, quantity, numOfBatches]);

  return (
    <ModalContainer>
      {
        !isConfirmModalOpen && (
          <ControlMenuModal
            buttonTitle="INICIAR CARREGAMENTO"
            disableStartShipment={disableStartShipment}
            formula={formula}
            numOfBatches={numOfBatches}
            quantity={quantity}
            setFormula={setFormula}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
            setModalOpen={setCreateShipmentModalOpen}
            setNumOfBatches={setNumOfBatches}
            setQuantity={setQuantity}
          />
        )
      }
      {
        isConfirmModalOpen && (
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            toggleModal={toggleConfirmModal}
            quantityValue={quantity}
            formulaCode={formula.code}
            concreteFormulaId={formula.value}
            onRefetch={refetchSequence}
            numberOfShipments={numOfBatches}
          />
        )
      }
    </ModalContainer>
  );
};
