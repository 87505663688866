import gql from "graphql-tag";

export const FORMULAS_QUERY = gql`
  query {
    concreteFormulas(active: true) {
      id
      code
      fck
      concreteFamily {
        slump {
          id
          nominal
          deviation
        }
      }
    }
  }
`;

export const SHIPMENT_CONTRACT_INFOS = gql`
  query {
    shipmentContractInfos {
      equipmentPlates
      contractInfos {
        contractCode
        constructionName
        constructionAddress
      }
    }
  }
`
