import React, { useEffect, useState } from "react";
import {
  Col,
} from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import {
  TableContainer,
  Table,
  Header,
  Row,
  Data
} from "../../../components/Table/tableComponents";

import { httpClient } from "../../../settings/apolloClient";

import CONCRETE_FORMULA_QUERY from "../../FamiliesAndFormulas/ConcreteTracesModal/concreteFormulaQueries";

const formatDecimal = (value) => {
  let formattedValue;
  if (value < 100) {
    formattedValue = value.toFixed(2);
  } else {
    formattedValue = value.toFixed(1);
  };
  formattedValue = formattedValue.replace(/(\.\d)0$/, "$1");
  return formattedValue;
};

const CorrectedQuantity = ({ value, unit }) => (
  <td style={{ textAlign: "center", fontWeight: 600, color: parseFloat(value) < 0 ? "red" : "" }}>
    {`${formatDecimal(value)} ${unit}`}
  </td>
);

export const MaterialTable = ({
  inventoryItemsData,
  setNewFormulaMaterials,
  selectedShipment,
  returnedQuantity,
  formula: {
    value
  },
  quantity,
}) => {
  const [tableData, setTableData] = useState([]);

  const {
    inventoryItems
  } = inventoryItemsData || [];

  const {
    data: concreteFormulaData,
  } = useQuery(CONCRETE_FORMULA_QUERY, {
    variables: {
      id: value,
    },
    fetchPolicy: "network-only",
    client: httpClient,
  });

  const {
    concreteFormula
  } = concreteFormulaData || {};

  useEffect(() => {
    if (inventoryItems.length > 0 && concreteFormula && selectedShipment) {
      const newTableData = inventoryItems.filter(({ inventoryLocations }) => (inventoryLocations && inventoryLocations.length > 0)).map(({
        id,
        name,
        unit,
      }) => {
        const consumed = selectedShipment.inventoryChanges.length > 0 ? selectedShipment.inventoryChanges.reduce((acc, {
          inventoryItem: {
            id: itemId,
          },
          quantity: changeQuantity,
        }) => {
          if (itemId === id) {
            return acc + parseFloat(changeQuantity) * (-1);
          }
          return acc;
        }, 0) : 0;
        const returned = consumed * (returnedQuantity / selectedShipment.quantity);
        const formulaItem = concreteFormula.formulaMaterials.find(({ inventoryItem: { id: itemId } }) => itemId === id);
        const formula = formulaItem ? formulaItem.weight * quantity : 0;
        const correctedQuantity = formula - returned;
        const order = formulaItem ? formulaItem.order : 999;
        const specificWeight = formulaItem ? formulaItem.specificWeight : 1;
        const proportion = formulaItem ? formulaItem.proportion : 1;
        const supplierId = formulaItem ? formulaItem.supplier.id : 1;
        return {
          id,
          name,
          consumed,
          returned,
          formula,
          unit,
          correctedQuantity,
          order,
          specificWeight,
          proportion,
          supplierId,
        };
      });
      newTableData.sort((a, b) => a.order - b.order);
      setNewFormulaMaterials(newTableData.filter(({ formula, consumed }) => (formula !== 0 || consumed !== 0)).map(({
        id: inventoryItemId,
        order,
        specificWeight,
        proportion,
        correctedQuantity: weight,
        supplierId,
      }) => ({
        inventoryItemId,
        order,
        specificWeight,
        proportion,
        weight: formatDecimal(weight / quantity),
        supplierId,
      })));
      setTableData(newTableData);
    }
  }, [inventoryItems, selectedShipment, returnedQuantity, concreteFormula, quantity]);

  return (
    <TableContainer
      style={{
        border: "1px solid #f6f8ff"
      }}
    >
      <Table>
        <thead>
          <tr>
            <Header>Material</Header>
            <Header>Consumido na Carga</Header>
            <Header>Retornado</Header>
            <Header>Quantidade teórica</Header>
            <Header>Quantidade corrigida</Header>
          </tr>
        </thead>
        <tbody>
          {tableData.map(({
            id: itemId,
            name: itemName,
            unit,
            consumed,
            returned,
            formula,
            correctedQuantity,
          }) => (
            <Row
              key={itemId}
              padding="10px"
            >
              <Data>{itemName}</Data>
              <Data>{`${formatDecimal(consumed)} ${unit}`}</Data>
              <Data>{`${formatDecimal(returned)} ${unit}`}</Data>
              <Data>{`${formatDecimal(formula)} ${unit}`}</Data>
              <CorrectedQuantity value={correctedQuantity} unit={unit} />
            </Row>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};
