import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";

const BLANK = "Não informado";

const padNumber = (number) => (number < 10 ? `0${number}` : number);

export const ShipmentSelect = ({
  shipments,
  setSelectedShipmentId,
}) => {
  const [value, setValue] = useState(null);

  const onChange = (selectedOption) => {
    setValue(selectedOption);
  };

  useEffect(() => {
    if (value) {
      setSelectedShipmentId(value.value);
    }
  }, [value]);

  const formatTime = (time) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${padNumber(hours)}:${padNumber(minutes)}`;
  };

  const getEquipmentPlate = (shipmentInfo) => {
    if (shipmentInfo && shipmentInfo.equipmentPlate) {
      return shipmentInfo.equipmentPlate;
    }
    return BLANK;
  };

  return (
    <div style={{ width: "100%", fontWeight: "bold", padding: 10 }}>
      <ReactSelect
        className="react-select dark"
        classNamePrefix="react-select"
        name="itemSelected"
        value={value}
        onChange={(selectedOption) => onChange(selectedOption)}
        options={[
          {
            value: "",
            isDisabled: true
          },
          ...shipments.map(({
            id,
            code,
            quantity,
            shipmentInfo,
            loadingEndTime,
            concreteFormula: {
              fck,
            }
          }) => ({
            value: id,
            code,
            label: `Saída ${formatTime(loadingEndTime)} | Placa ${getEquipmentPlate(shipmentInfo)} | Fck ${fck} | ${quantity}m3 | Código ${code}`,
          }))
        ]}
        placeholder="Escolha..."
      />
    </div>
  );
};
