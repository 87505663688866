import React from "react";
import { withQuery } from "containers/withQuery";
import { MaterialBoxesContainer } from "./confirmModalComponents";
import MaterialBox from "./MaterialBox";
import { CONCRETE_FORMULA_QUERY } from "./confirmModalQueries";
import { useMobileDevice } from "../../utils/getDeviceType";

const MaterialBoxes = ({ concreteFormula, quantity }) => {
  const isMobile = useMobileDevice();

  return isMobile ? ( // 🔹 Correção: Removidas chaves extras `{}` ao redor do ternário
    <React.Fragment>
      <MaterialBoxesContainer>
        <MaterialBox title="Volume Total" value={`${quantity} m³`} />
        <MaterialBox title="Slump" value={`${concreteFormula.concreteFamily.slump.nominal} ± ${concreteFormula.concreteFamily.slump.deviation}`} />
        <MaterialBox title="Pedra" value={concreteFormula.concreteFamily.gravelType} />
      </MaterialBoxesContainer>
      <MaterialBoxesContainer
        style={{
          marginTop: "4px"
        }}
      >
        <MaterialBox title="Resistência" value={`Fck ${concreteFormula.fck}`} />
        <MaterialBox title="Uso" value={concreteFormula.use} />
      </MaterialBoxesContainer>
    </React.Fragment>
  ) : (
    <MaterialBoxesContainer>
      <MaterialBox title="Volume Total" value={`${quantity} m³`} />
      <MaterialBox title="Slump" value={`${concreteFormula.concreteFamily.slump.nominal} ± ${concreteFormula.concreteFamily.slump.deviation}`} />
      <MaterialBox title="Resistência" value={`Fck ${concreteFormula.fck}`} />
      <MaterialBox title="Pedra" value={concreteFormula.concreteFamily.gravelType} />
      <MaterialBox title="Uso" value={concreteFormula.use} small />
    </MaterialBoxesContainer>
  );
};

export default withQuery(({ formulaCode, onConcreteFormulaQueryCompleted }) => ({
  query: CONCRETE_FORMULA_QUERY,
  variables: {
    formulaCode,
  },
  fetchPolicy: "no-cache",
  onCompleted: onConcreteFormulaQueryCompleted,
}))(MaterialBoxes);
