import React, { useState, useEffect } from "react";
import "./LoadSlider.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Input } from "reactstrap";

const MIN_VALUE = 0.5;
const MAX_VALUE = 11;
const STEP = 0.5;

const LoadSlider = ({
  onChange,
  value = MIN_VALUE,
  lightText,
  title = "Quantidade",
  max,
  textColor,
}) => {
  const color = textColor || (lightText ? "#fff" : "#2E384D");
  const inputColor = textColor || "#D3D3D3";
  const [maxValue, setMaxValue] = useState(MAX_VALUE);

  const handleInputChange = (e) => {
    const newValue = Math.trunc(parseFloat(e.target.value) * 10) / 10;
    if (newValue >= MIN_VALUE && newValue <= maxValue) {
      onChange(newValue);
    } else if (newValue < MIN_VALUE) {
      onChange(MIN_VALUE);
    } else if (newValue > maxValue) {
      onChange(maxValue);
    } else {
      onChange(5);
    }
  };

  useEffect(() => {
    if (max) {
      setMaxValue(max);
    }
  }, [max]);

  return (
    <div className="load-slider-container">
      <div className="load-slider-info">
        <h3 style={{ color }}>{title}</h3>
        <div
          style={{
            display: "inline-flex",
            alignItems: "right",
            justifyContent: "center",
            color: "#D3D3D3",
            cursor: "default",
          }}
        >
          <Input
            value={parseFloat(value).toFixed(1)}
            className="number-input"
            type="number"
            inputMode="decimal"
            onChange={handleInputChange}
            min={0.5}
            max={maxValue}
            style={{
              width: "60px",
              height: "40px",
              display: "block",
              textAlign: "right",
              align: "right",
              backgroundColor: "transparent",
              color: inputColor,
              fontSize: "20px",
              fontWeight: "bold",
              border: "none",
              paddingRight: "5px",
              cursor: "default",
            }}
          />
          <b
            style={{
              paddingTop: "12px",
              paddingLeft: "2px",
              color: inputColor,
            }}
          >
            m³
          </b>
        </div>
      </div>

      <Slider
        onChange={onChange}
        value={parseFloat(value).toFixed(1)}
        min={MIN_VALUE}
        max={maxValue}
        step={STEP}
        style={{ marginTop: "10px", color: "#1D84C6" }}
      />
    </div>
  );
};

export default LoadSlider;
