import gql from "graphql-tag";

export const CREATE_CONCRETE_FORMULA_FROM_BASE_MUTATION = gql`
mutation createConcreteFormulaFromBase($baseFormulaId: ID!, $formulaMaterials: [CreateFormulaMaterialParams]!) {
  createConcreteFormulaFromBase(baseFormulaId: $baseFormulaId, formulaMaterials: $formulaMaterials) {
    id
    code
  }
}
`;
