import React from "react";
import { Subscription } from "react-apollo";
import SUBSCRIBE_TO_SENSOR_CHANGES from "../graphql/subscriptions/subscribeToSensorMeasurementChanges.js";


export const SensorSubscription = ({ id, name, unit }) => {
  return (
    <Subscription
      subscription={SUBSCRIBE_TO_SENSOR_CHANGES}
      fetchPolicy="no-cache"
      variables={{
        id
      }}
    >
      {({ data }) => <Sensor data={data} name={name} unit={unit} />}
    </Subscription>
  );
};

const Sensor = ({ data, name, unit }) => {
  const value = data ? data.subscribeToSensorMeasurementChanges.toFixed(1) : "0.0";
  const info = unit ? `${value} ${unit}` : value > 0 ? "ON" : "OFF";
  return (
    <span>
      <h2>
        { info }
      </h2>
      <h5>{ name }</h5>
    </span>
  );
};
