import gql from "graphql-tag";

export default gql`
  query {
    inventoryItems {
      id
      name
      inventoryLocations {
        id
        name
        inventoryQuantity
      }
    }
  }
`;
