import React, { useState, useRef, useEffect } from "react";
import CreatableSelect from "react-select/lib/Creatable";
import { InfoContainer, InfoText } from "../ConfirmModal/confirmModalComponents";
import PencilImg from "../../assets/img/pencil-edit-button.svg";

export default function InfoContainerComponent({
  title,
  value,
  canEdit,
  options,
  handleChange,
  setOptions,
}) {
  const [editInfo, setEditInfo] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleSelect = (selectedOption) => {
    if (selectedOption) {
      handleChange(selectedOption.value);
    } else {
      handleChange("");
    }
    setEditInfo(false);
  };

  const handleCreate = (newValue) => {
    if (!newValue) return;
    const newOption = { value: newValue, label: newValue };
    setOptions((prevOptions) => [...prevOptions, newOption]);
    handleChange(newValue);
    setEditInfo(false);
  };

  const handleBlur = () => {
    if (inputValue && !options.some((option) => option.value === inputValue)) {
      handleCreate(inputValue);
    } else {
      setEditInfo(false);
    }
  };

  return (
    <InfoContainer>
      <h3 style={{ fontWeight: "400" }}>{title}</h3>
      <div style={{ margin: "auto", marginTop: "10px", maxWidth: "95%" }}>
        {canEdit ? (
          <div>
            {!editInfo ? (
              <div
                onClick={() => setEditInfo(true)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <InfoText>{value || "Não Informado"}</InfoText>
                <img src={PencilImg} alt="edit" style={{ height: "0.8rem" }} />
              </div>
            ) : (
              <CreatableSelect
                style={{ marginTop: "10px" }}
                isClearable
                options={options}
                onChange={handleSelect}
                onCreateOption={handleCreate}
                value={options.find((item) => item.value === value) || null}
                placeholder="Não Informado"
                onInputChange={(val) => setInputValue(val)}
                onBlur={handleBlur}
                autoFocus
                menuIsOpen
                formatCreateLabel={(newValue) => `Adicionar "${newValue}"`}
              />
            )}
          </div>
        ) : (
          <InfoText>{value || "Não Informado"}</InfoText>
        )}
      </div>
    </InfoContainer>
  );
}
