import React, { useState, useRef } from "react";
import styled from "styled-components";

const TooltipContainer = styled.div`
  position: relative;
  margin-left: 14px;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -10px;
  left: 100%;
  width: 300px;
  padding: 5px 10px;
  background-color: black;
  color: white;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 1100;
  margin-left: 8px;
  border-radius: 6px;
  &.show {
    opacity: 1;
  }
`;

const TooltipTrigger = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

const QuestionIcon = styled.div`
  background-color: black;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const TooltipComponent = ({ children, tooltipTexts }) => {
  const tooltipRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <TooltipContainer>
      <TooltipTrigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children || <QuestionIcon>?</QuestionIcon>}
      </TooltipTrigger>
      <Tooltip ref={tooltipRef} className={isTooltipVisible ? "show" : ""}>
        {tooltipTexts.map((text, index) => (
          <div key={index}>{text}</div>
        ))}
      </Tooltip>
    </TooltipContainer>
  );
};

export default TooltipComponent;
