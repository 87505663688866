import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";

import { useQuery } from "@apollo/react-hooks";
import { httpClient } from "../../settings/apolloClient";

import { SimpleDataContainer } from "./confirmModalComponents";
import InfoContainer from "../FormTextField/InfoContainer";
import MaterialTable from "./MaterialTable";
import MaterialBoxes from "./MaterialBoxes";
import { SHIPMENT_CONTRACT_INFOS } from "../../graphql/queries/get/gqlSelectQueries";
import TooltipComponent from "../Tooltip/Tooltip";

const styles = {
  withContract: {
    padding: "30px",
    borderTop: "2px solid #F6F8FF",
  },
  withoutContract: {
    padding: "0 30px 30px 30px",
  },
};

const OperationData = ({
  quantity,
  materials,
  formulaCode,
  onConcreteFormulaQueryCompleted,
  setShipmentInfo
}) => {
  const { data, loading, refetch } = useQuery(SHIPMENT_CONTRACT_INFOS, {
    fetchPolicy: "network-only",
    client: httpClient,
  });
  const [selectedContract, setSelectedContract] = useState("");
  const [selectedConstruction, setSelectedConstruction] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState("");

  const [contractOptions, setContractOptions] = useState([]);
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [negativeMaterial, setNegativeMaterial] = useState(false);
  useEffect(() => {
    if (data && data.shipmentContractInfos) {
      const contracts = (data.shipmentContractInfos.contractInfos || [])
        .filter(item => item)
        .map((item) => ({
          value: item.contractCode,
          label: item.contractCode,
        }));
      const constructions = (data.shipmentContractInfos.contractInfos || [])
        .filter(item => item)
        .map((item) => ({
          value: item.constructionName,
          label: item.constructionName,
        }));
      const addresses = (data.shipmentContractInfos.contractInfos || [])
        .filter(item => item)
        .map((item) => ({
          value: item.constructionAddress,
          label: item.constructionAddress,
        }));
      const equipments = (data.shipmentContractInfos.equipmentPlates || [])
        .filter(item => item)
        .map((plate) => ({
          value: plate,
          label: plate,
        }));
      setContractOptions(contracts);
      setConstructionOptions(constructions);
      setAddressOptions(addresses);
      setEquipmentOptions(equipments);
    }
  }, [data]);
  useEffect(() => {
    if (typeof setShipmentInfo === "function") {
      setShipmentInfo({
        contractCode: selectedContract,
        constructionName: selectedConstruction,
        constructionAddress: selectedAddress,
        equipmentPlate: selectedEquipment
      });
    }
  }, [selectedAddress, selectedConstruction, selectedContract, selectedEquipment]);

  useEffect(() => {
    setNegativeMaterial(materials.some(material => parseFloat(material.weight) < 0));
  }, [materials]);

  return (
    <div style={styles.withoutContract}>
      <MaterialBoxes
        formulaCode={formulaCode}
        onConcreteFormulaQueryCompleted={onConcreteFormulaQueryCompleted}
        quantity={quantity}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "30px"
        }}
      >
        <h3>
          Informações Complementares
        </h3>
        <TooltipComponent
          tooltipTexts={[
            "As informações complementares abaixo não são obrigatórias."
          ]}
        />
      </div>
      <SimpleDataContainer style={{ justifyContent: "flex-start" }}>
        <Col sm={12} md={12} lg={6}>
          <InfoContainer
            title="Código de Contrato"
            value={selectedContract}
            canEdit
            options={contractOptions || []}
            handleChange={setSelectedContract}
            setOptions={setContractOptions}
          />
        </Col>
        <Col sm={12} md={12} lg={6}>
          <InfoContainer
            title="Nome da Obra ou Cliente"
            value={selectedConstruction}
            canEdit
            options={constructionOptions || []}
            handleChange={setSelectedConstruction}
            setOptions={setConstructionOptions}
          />
        </Col>
        <Col sm={12} md={12} lg={6}>
          <InfoContainer
            title="Endereço"
            value={selectedAddress}
            canEdit
            options={addressOptions || []}
            handleChange={setSelectedAddress}
            setOptions={setAddressOptions}
          />
        </Col>
        <Col sm={12} md={12} lg={6}>
          <InfoContainer
            title="Placa Caminhão"
            value={selectedEquipment}
            canEdit
            options={equipmentOptions || []}
            handleChange={setSelectedEquipment}
            setOptions={setEquipmentOptions}
          />
        </Col>
      </SimpleDataContainer>

      <MaterialTable
        materials={materials}
        negativeMaterial={negativeMaterial}
      />
    </div>
  );
};

export default OperationData;
