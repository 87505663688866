import gql from "graphql-tag";

export default gql`
  query {
    inventoryItems {
      id
      name
      unit
      materialType
      inventoryLocations {
        id
        name
        inventoryQuantity
        sensor {
          id
          name
        }
      }
    }
  }
`;
