import React from "react";
import {
  Col,
  Row
} from "reactstrap";
import { Title2, Subtitle } from "../StyledComponents";

const BLANK = "Não informado";

const InfoCard = ({ title, items }) => (
  <div style={{
    width: "100%",
    alignContent: "flex-start"
  }}
  >
    <Row
      noGutters
      style={{
        marginBottom: "6px",
        marginTop: "12px"

      }}
    >
      <Title2>{title}</Title2>
    </Row>
    <div>
      {items.map(({ label, value }, index) => (
        <Row
          key={index}
          noGutters
          style={{
            marginLeft: "8px"
          }}
        >
          <Col sm={6}>
            <Subtitle>{label}</Subtitle>
          </Col>
          <Col sm={6}>
            <Subtitle>{value || BLANK}</Subtitle>
          </Col>
        </Row>
      ))}
    </div>
  </div>
);

export default InfoCard;
