import React from "react";
import {
  Button,
  Modal,
} from "reactstrap";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose, graphql } from "react-apollo";
import { trimEnd } from "lodash";
import { withTruckStateConsumer, TruckState } from "contexts/TruckContext/TruckStateContext.jsx";
import { withQuery } from "containers/withQuery";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import update from "immutability-helper";
import { convertArrayToObject } from "utils/convertArrayToObject";
import { withERP } from "utils/withERP";
import isBlank from "utils/isBlank";

import ConstructionData from "./ConstructionData";
import OperationData from "./OperationData";
import WaterQuantity from "./WaterQuantity";
import MaterialsLocation from "./MaterialsLocation";

import Danfe from "./Danfe";
import { FORMULA_MATERIAL_TABLE_QUERY } from "./confirmModalQueries";
import Checkbox from "./Checkbox";
import { CREATE_CONCRETE_SHIPMENT_MUTATION } from "./confirmModalMutations";

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: #2E384D;
`;

const Container = styled.div`
  padding: 0 30px 30px 30px;
`;

const INITIAL_SLIDER_VALUE = 80;
const WATER = "WATER";

const getMaterialWeight = (weight, coefficient) => parseFloat(weight * coefficient / 100);

const distributeWeights = (inventoryLocations, weight) => {
  const weights = {};
  const locations = inventoryLocations.filter(({ inventoryQuantity }) => parseFloat(inventoryQuantity) > 0);

  locations.sort(({ inventoryQuantity: a }, { inventoryQuantity: b }) => parseFloat(a) - parseFloat(b));

  const missingWeight = locations.reduce((rest, { inventoryQuantity: quantity, id }, index) => {
    const isLastWeight = index === locations.length - 1;
    const thisWeight = (isLastWeight || rest <= quantity) ? rest : quantity;
    const newRest = rest <= quantity ? 0 : rest - quantity;

    weights[id] = thisWeight;

    return newRest;
  }, weight);

  return {
    ...weights,
    missingWeight,
  };
};

const buildMaterial = material => {
  const materialWeight = parseFloat(material.weight);
  const weights = distributeWeights(material.inventoryItem.inventoryLocations, materialWeight);

  return {
    id: material.id,
    inventoryItemId: material.inventoryItem.id,
    name: material.inventoryItem.name,
    order: material.order,
    materialType: material.inventoryItem.materialType,
    materialUnit: material.inventoryItem.unit,
    formulaWeight: materialWeight,
    missingWeight: parseFloat(weights.missingWeight),
    lastHumidityMeasurementId: material.inventoryItem.lastHumidityMeasurement ? material.inventoryItem.lastHumidityMeasurement.id : undefined,
    locations: material.inventoryItem.inventoryLocations.reduce((locations, { id, name, inventoryQuantity: quantity }) => ({
      ...locations,
      [id]: {
        id,
        name,
        quantity: parseFloat(quantity),
        weight: weights[id]
      }
    }), {})
  };
};

const updateMaterial = (material, weight) => {
  const locationWeights = Object.values(material.locations)
    .map(({ id, quantity }) => ({ id, inventoryQuantity: quantity }));
  const weights = distributeWeights(locationWeights, weight);

  return update(material, {
    formulaWeight: { $set: weight },
    missingWeight: { $set: weights.missingWeight },
    locations: {
      $apply: (locations) => Object.values(locations).reduce((acc, location) => ({
        ...acc,
        [location.id]: {
          ...location,
          weight: weights[location.id]
        }
      }), {})
    }
  });
};

const constructTable = ({ __typename, ...materials }) => convertArrayToObject(
  Object.values(materials)
    .filter(material => !!material)
    .reduce((result, material) => result.concat(material), [])
    .map(buildMaterial),
  (material) => material.id
);

const formatTable = materials => materials.reduce((result, { name, locations }) => (
  result.concat(
    Object.values(locations).map(({
      id,
      name: location,
      quantity,
      weight
    }) => ({
      id,
      location,
      name,
      weight: parseFloat(weight || 0).toFixed(2),
      quantity: parseFloat(quantity || 0).toFixed(2),
      newQuantity: parseFloat((quantity - weight) || 0).toFixed(2)
    })).flat()
  )
), []);

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.water = this.props.formulaMaterialTable.water;
    this.waterWeight = Boolean(this.water) && this.water.length > 0 ? parseFloat(this.props.formulaMaterialTable.water[0].weight) : 0;
    this.hasContract = this.props.data.client && this.props.data.contract;

    this.state = {
      loadingMutation: false,
      sliderValue: INITIAL_SLIDER_VALUE,
      hasSpecimen: true,
      generateDanfe: props.isErpAvailable,
      sealNumber: "",
      code: "",
      materials: constructTable(this.props.formulaMaterialTable),
      waterCut: this.waterWeight ? parseFloat(this.waterWeight - getMaterialWeight(this.waterWeight, INITIAL_SLIDER_VALUE)).toFixed(2) : 0,
    };
  }

  onChangeMaterialRatio = (materialId, locationId, weight) => this.setState(state => update(state, {
    materials: {
      [materialId]: {
        locations: {
          [locationId]: {
            weight: {
              $set: weight
            }
          }
        }
      }
    }
  }), () => this.setState(state => {
    const material = state.materials[materialId];
    const sum = Object.values(material.locations)
      .reduce((acc, location) => parseFloat(acc) + parseFloat(location.weight || 0),
        0);
    const missingWeight = material.formulaWeight - sum;

    return update(state, {
      materials: {
        [materialId]: {
          missingWeight: {
            $set: missingWeight
          }
        }
      }
    });
  }));

  onRecalculateMaterialRatio = (materialId, locationIds, missingWeight) => {
    const material = this.state.materials[materialId];
    const weights = distributeWeights(
      Object.values(material.locations)
        .filter(location => locationIds.includes(location.id))
        .map(({ id, quantity }) => ({ id, inventoryQuantity: quantity })),
      missingWeight
    );

    this.setState(state => update(state, {
      materials: {
        [materialId]: {
          missingWeight: { $set: weights.missingWeight },
          locations: {
            $apply: (locations) => Object.values(locations).reduce((acc, location) => ({
              ...acc,
              [location.id]: {
                ...location,
                weight: weights[location.id] === undefined ? location.weight : weights[location.id]
              }
            }), {})
          }
        }
      }
    }));
  };

  onChangeSlider = sliderValue => this.setState(({ materials }) => {
    const water = Object.values(materials).find(material => material.materialType === WATER);
    const newWeight = getMaterialWeight(this.waterWeight, sliderValue);

    return {
      sliderValue,
      materials: {
        [water.id]: updateMaterial(water, newWeight),
        ...convertArrayToObject(
          Object.values(materials).filter(material => material.materialType !== WATER),
          material => material.id
        ),
      },
      waterCut: parseFloat(this.waterWeight - newWeight).toFixed(2)
    };
  });

  onChangeSpecimen = () => this.setState(({ hasSpecimen }) => ({ hasSpecimen: !hasSpecimen }));

  onChangeGenerateDanfe = () => this.setState(({ generateDanfe }) => ({ generateDanfe: !generateDanfe }));

  onChangeSeal = e => this.setState({ sealNumber: trimEnd(e.target.value) });

  onChangeCode = e => this.setState({ code: trimEnd(e.target.value) });

  onStartShipment = () => {
    const {
      createConcreteShipment,
      shipmentId,
      data,
      quantityValue,
      onRefetch,
      toggleModal,
    } = this.props;

    const {
      code,
      sealNumber,
      hasSpecimen,
      materials,
      waterCut,
    } = this.state;

    const locations = Object.values(materials)
      .map(material => Object.values(material.locations)
        .map(({ name, weight, quantity }) => ({ name, weight, quantity })))
      .flat();

    const insufficientLocation = locations.find(({ quantity, weight }) => weight > quantity);
    if (insufficientLocation) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        `${insufficientLocation.name} não possui materiais suficientes em estoque.`
      );
      return;
    }

    const blankLocation = locations.find(({ weight }) => isBlank(weight));
    if (blankLocation) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        `${blankLocation.name} não possui um peso definido.`
      );
      return;
    }

    const negativeLocation = locations.find(({ weight }) => weight < 0);
    if (negativeLocation) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        `${negativeLocation.name} não pode ser menor que 0.`
      );
      return;
    }

    if (Object.values(materials).some(({ missingWeight }) => missingWeight > 0)) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        "Não pode haver quantidade faltante para nenhum material."
      );
      return;
    }

    if (Object.values(materials).some(({ missingWeight }) => missingWeight < 0)) {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.danger,
        "Você selecionou uma quantidade excedente. Por favor, confira a distribuição dos materiais."
      );
      return;
    }

    const shipmentMaterials = Object.values(materials).map(material => ({
      order: material.order,
      inventoryItemId: material.inventoryItemId,
      humidityMeasurementId: material.lastHumidityMeasurementId,
      shipmentMaterialLocations: Object.values(material.locations).map(location => ({
        formulaWeight: parseFloat(location.weight),
        inventoryLocationId: location.id
      }))
    }));

    this.setState({ loadingMutation: true });
    createConcreteShipment({
      variables: {
        operatorIds: this.hasContract ? this.mapId(data.operatorsSelected).concat(this.mapId(data.teamSelected)) : undefined,
        params: {
          quantity: quantityValue,
          sealNumber,
          code,
          proofBody: hasSpecimen,
          equipmentShipmentId: shipmentId,
          concreteFormulaId: this.concreteFormulaId,
          shipmentMaterials,
          waterCut
        }
      }
    })
      .then(({ data: { createConcreteShipment: { invoiceRequestError } } }) => {
        if (invoiceRequestError) {
          this.props.snackbarContext.setNotificationTimeOut("danger", invoiceRequestError);
        }

        this.props.truckStateContext.setTruckState(TruckState.WAITING);
        if (this.hasContract) {
          this.props.history.push("/admin/instruments_panel");
        } else {
          toggleModal();
          onRefetch();
        }
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      })
      .finally(() => this.setState({ loadingMutation: false }));
  }

  mapId = array => array.map(({ id }) => id)

  render() {
    const {
      data,
      isOpen,
      toggleModal,
      formulaMaterialTable,
      quantityValue,
      formulaCode,
    } = this.props;

    const materials = Object.values(this.state.materials);
    const filteredMaterials = materials
      .filter(material => (
        Object.keys(material.locations).length > 1
        && material.materialType !== WATER
      ));
    const title = this.hasContract ? "Confirme os dados da obra" : "Dados da operação";

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        size="lg"
        zIndex={2040}
      >
        <div className="modal-header justify-content-center" style={{ display: "block", border: "none" }}>
          <div style={{
            display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center",
          }}
          >
            <Title>{title}</Title>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
          </div>
        </div>
        <div className="modal-body" style={{ padding: 0 }}>
          {this.hasContract && <ConstructionData client={data.client} contract={data.contract} />}

          <OperationData
            hasContract={this.hasContract}
            equipmentCode={data.equipmentCode}
            quantity={quantityValue}
            coefficient={this.state.sliderValue}
            formulaMaterialTable={formulaMaterialTable}
            formulaCode={formulaCode}
            operatorsSelected={data.operatorsSelected}
            teamSelected={data.teamSelected}
            onConcreteFormulaQueryCompleted={({ concreteFormula }) => { this.concreteFormulaId = concreteFormula.id; }}
            materials={formatTable(materials)}
          />
          <MaterialsLocation
            materials={filteredMaterials}
            onChangeMaterialRatio={this.onChangeMaterialRatio}
            onRecalculateMaterialRatio={this.onRecalculateMaterialRatio}
          />
          <Container>
            <Checkbox
              value={this.state.hasSpecimen}
              onChange={this.onChangeSpecimen}
              label="Moldar corpo de prova"
            />
          </Container>
          <WaterQuantity
            waterQuantity={this.waterWeight}
            coefficient={this.state.sliderValue}
            onChangeSlider={this.onChangeSlider}
            sands={formulaMaterialTable.sand}
          />
          <Danfe
            onChangeSeal={this.onChangeSeal}
            sealNumber={this.state.sealNumber}
            onChangeCode={this.onChangeCode}
            code={this.state.code}
            onChangeGenerateDanfe={this.onChangeGenerateDanfe}
            generateDanfe={this.state.generateDanfe}
          />

        </div>
        <div className="modal-footer">
          <Button
            style={{ width: "100%", padding: "17px 16px", margin: 0 }}
            color="info"
            data-dismiss="modal"
            type="button"
            onClick={this.onStartShipment}
            disabled={this.state.loadingMutation}
          >
            {
              this.state.loadingMutation
                ? "Carregando..."
                : "Iniciar Carregamento"
            }
          </Button>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withQuery(({
    refetch,
    formulaCode,
    quantityValue,
    toggleModal,
  }) => ({
    noLoader: true,
    onError: toggleModal,
    query: FORMULA_MATERIAL_TABLE_QUERY,
    variables: {
      formulaCode,
      quantity: quantityValue,
    },
    fetchPolicy: refetch ? "network-only" : "cache-and-network",
  })),
  graphql(CREATE_CONCRETE_SHIPMENT_MUTATION, { name: "createConcreteShipment" }),
  withRouter,
  withTruckStateConsumer,
  withSnackbarConsumer,
  withERP,
)(ConfirmModal);
