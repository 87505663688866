import styled, { css } from "styled-components";

const IMAGE_WIDTH = 1920;
const SMALL_DEVICE_WIDTH = "430px";


export const SharedStyle = css`
  background-color: #2F4050;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
  border-radius: 10px 0 0 10px;
`;

export const arrowStyle = css`
  transform-origin: 0% 0% 0;

  @media screen and (max-width: 800px) {
    transform: scale(1) translate(0, 0);
  }
`;

export const ArrowBottom = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH})
    translate(-50%, -100%);
  ${arrowStyle}
`;

export const ArrowLeft = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH})
    translate(0, -50%);
  ${arrowStyle}
`;

export const ArrowRight = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH})
    translate(-100%, -50%);
  ${arrowStyle}
`;

export const ArrowTop = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH})
    translate(-50%, 0);
  ${arrowStyle}
`;

export const ButtonContainer = styled.div`
  width: 300px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
`;

export const CircularIcon = styled.div`
  background-color: ${props => props.backgroundColor || "#000"};
  width: ${props => props.size || 46}px;
  height: ${props => props.size || 46}px;
  border-radius: ${props => props.size || 46}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  margin: 0 5px;
`;

export const ErrorParagraph = styled.p`
  font-size: 14px;
  font-family: 'Rubik';
  color: #FB404B;
  font-weight: 400;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  margin-top: 25px;
`;

export const Grid = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
`;

export const H3 = styled.h3`
  font-family: 'Rubik';
  color: #2E384D;
  font-size: 20px;
`;

export const Hr = styled.hr`
  border-color: #ddd !important;
`;

export const Icon = styled.i`
  color: #fff;
  font-weight: 100;
  margin-left: 10px;
`;

export const IconContainer = styled.div`
  ${SharedStyle}
  top: 0px;
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 2012;

  &::before {
    top: 0px;
    left: 50px;
    position: absolute;
    content: "";
    background-color: #2f4050;
    height: calc(100% + 12px);
    width: 15px;
  }
`;

export const InfoParagraph = styled.p`
  font-size: 14px;
  font-family: 'Rubik';
  color: #8798AD;
  font-weight: 400;
`;

export const InstrumentsPanelWrapper = styled.div`
  position: relative;
`;

export const Label = styled.p`
  font-size: 14px;
  font-family: 'Rubik';
  color: #2E384D;
  font-weight: 500;
`;

export const Overlay = styled.div`
  display: ${props => (props.open !== null && props.show ? "block" : "none")};
  position: fixed;
  top: 0ox;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(47, 64, 80, 0.74);
`;

export const PlantCard = styled.div`
  position: absolute;
  @media screen and (min-width: 801px) {
    z-index: 0;
  }
  top: ${props => props.top}%;
  left: ${props => props.left}%;

  @media screen and (max-width: 800px) {
    position: relative;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 15px;
  }
`;

export const PlantContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isVertical ? "column" : "row")};
  justify-content: flex-start;
  align-items: stretch;
  padding: ${props => (props.isButton ? "0" : "5px")};

  @media screen and (max-width: ${SMALL_DEVICE_WIDTH}) {
    flex-wrap: wrap;
  }
`;

export const PlantContent = styled.div`
  display: block;
  padding: 5px;
  width: calc((${({ cols }) => cols} * 200px) + 10px);

  :not(:last-child) {
    border-right: ${props => (props.isContainerVertical ? "" : "2px solid #F1F4FE")};
  }

  @media screen and (max-width: ${SMALL_DEVICE_WIDTH}) {
    flex-wrap: wrap;
    border-right: none;
  }
`;

export const PlantItem = styled.div`
  display: inline-block;
  width: 200px;
`;

export const PlantTitle = styled.div`
  min-width: 200px;
  font-weight: bold;
  color: #2E384D;
  padding: 10px 15px;
  border-bottom: ${props => (props.isVertical ? "none" : "2px solid #F1F4FE")};
  @media screen and (max-width: ${SMALL_DEVICE_WIDTH}) {
    flex-wrap: wrap;
    border-bottom: none;
  }
`;


export const Span = styled.span`
  font-size: 13px;
  font-family: 'Rubik';
  color: #8798AD;
  font-weight: 400;
`;

export const Subtitle = styled.h3`
  font-size: 13px;
  font-family: 'Rubik';
  color: #707070;
  font-weight: 300;
  line-height: 1.4;
`;

export const Title = styled.h3`
  font-size: 16px;
`;

export const UpperTitle = styled.h3`
  font-size: 20px;
`;

export const Title2 = styled.h3`
`;

export const WhiteTitle = styled.p`
  color: #fff;
  text-align: center;
  margin: 20px;
  font-size: 15px;
  font-family: 'Rubik';
`;

export const ModalCard = styled.div`
  font-family: "Rubik";
  font-size: 13px;
  font-weight: 500;
  color: #283745;
  padding-bottom: 10px;
  border-bottom: ${({ noBottomMargin }) => (noBottomMargin ? "none" : "2px solid #fff")};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const Box = styled.div`
  background-color: #F6F8FF;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
`;
