import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  Col,
  Row
} from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import { compose, graphql } from "react-apollo";
import {
  Title,
  Subtitle,
  UpperTitle,
  ModalCard,
  Box
} from "../../../components/StyledComponents";

import { withSnackbarConsumer, Color } from "../../../contexts/SnackbarContext/SnackbarContext.jsx";
import { httpClient } from "../../../settings/apolloClient";
import CustomLoadSlider from "../../../components/CustomLoadSlider/CustomLoadSlider";
import InfoCard from "../../../components/Infos/InfoCard";

import { CREATE_CONCRETE_FORMULA_FROM_BASE_MUTATION } from "./graphql/mutations/createConcreteFormulaFromBase";
import { AVAILABLE_SHIPMENTS_QUERY } from "./graphql/queries/availableShipmentsQuery";
import { ShipmentSelect } from "./ShipmentSelect";
import { MaterialTable } from "./MaterialTable";
import FormulaSelect from "../ControlMenu/FormulaSelect";
import TooltipComponent from "../../../components/Tooltip/Tooltip";
import { useMobileDevice } from "../../../utils/getDeviceType";

const BLANK = "Não informado";

const maxTimeElapsed = 24;

const DeliveryInfo = ({ shipment }) => {
  const { code, shipmentInfo, sealNumber, quantity } = shipment || {};
  const { equipmentPlate } = shipmentInfo || {};

  const deliveryItems = [
    { label: "Cod. Carregamento", value: code },
    { label: "Placa", value: equipmentPlate },
    { label: "Lacre", value: sealNumber },
    { label: "Quantidade", value: `${quantity} m³` },
  ];

  return <InfoCard title="Entrega" items={deliveryItems} />;
};

const ContractInfo = ({ shipment }) => {
  const { shipmentInfo } = shipment || {};
  const { contractCode, constructionAddress, constructionName } = shipmentInfo || {};

  const contractItems = [
    { label: "Código", value: contractCode },
    { label: "Endereço", value: constructionAddress },
    { label: "Descrição", value: constructionName },
  ];

  return <InfoCard title="Contrato" items={contractItems} />;
};

const FormulaInfo = ({ shipment }) => {
  const { concreteFormula } = shipment || {};
  const { code, fck, concreteFamily } = concreteFormula || {};
  const { slump } = concreteFamily || {};
  const { nominal, deviation } = slump || {};

  const formulaItems = [
    { label: "Traço", value: code },
    { label: "Fck", value: fck },
    { label: "Descrição", value: `${nominal}±${deviation}` },
  ];

  return <InfoCard title="Concreto" items={formulaItems} />;
};

const padNumber = (number) => (number < 10 ? `0${number}` : number);

const formatDatetime = (datetime) => {
  if (!datetime) return BLANK;
  const date = new Date(datetime);
  return `${String(date.getHours()).padStart(2, "0")}h${String(date.getMinutes()).padStart(2, "0")} | 
          ${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;
};

const elapsedTime = (datetime) => {
  if (!datetime) return BLANK;
  const diff = new Date() - new Date(datetime);
  return Math.ceil(diff / 60000);
};

const TimelineInfo = ({ shipment }) => {
  const { startTime, loadingStartTime, loadingEndTime } = shipment || {};

  const timelineItems = [
    { label: "Início", value: formatDatetime(startTime) },
    { label: "Equipamento pronto", value: formatDatetime(loadingStartTime) },
    { label: "Término", value: formatDatetime(loadingEndTime) },
    { label: "Tempo decorrido", value: `${elapsedTime(loadingEndTime)} minutos` },
  ];

  return <InfoCard title="Horários" items={timelineItems} />;
};

const ReturnedShipmentModal = ({
  isOpen,
  setIsOpen,
  setControlMenuOpen,
  inventoryItemsData,
  snackbarContext,
  quantity,
  setQuantity,
  formula,
  setFormula,
  toggleConfirmModal,
  createConcreteFormulaFromBase,
  setReturnedQuantity,
  returnedQuantity = 0,
  setParentId
}) => {
  const [shipments, setShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedShipmentId, setSelectedShipmentId] = useState(null);
  const [newFormulaMaterials, setNewFormulaMaterials] = useState([]);
  const [enableStart, setEnableStart] = useState(false);
  const [allWeightsValid, setAllWeightsValid] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const isMobile = useMobileDevice();

  const {
    data: availableShipmentsData,
    loading,
  } = useQuery(AVAILABLE_SHIPMENTS_QUERY, {
    variables: {
      timeElapsed: maxTimeElapsed,
      shipmentType: "returned",
    },
    fetchPolicy: "network-only",
    client: httpClient,
  });

  useEffect(() => {
    if (availableShipmentsData) {
      setShipments(availableShipmentsData.availableConcreteShipments);
    }
  }, [availableShipmentsData]);

  useEffect(() => {
    if (selectedShipmentId) {
      const shipment = shipments.find(({ id }) => id === selectedShipmentId);
      setSelectedShipment(shipment);
      setParentId(shipment.id);
    }
  }, [selectedShipmentId, shipments, setParentId]);

  const handleFormulaSelect = (value) => setFormula(value);

  const handleChangeQuantity = (value) => setQuantity(value);

  const handleCreateReturnedShipmentFormula = async () => {
    createConcreteFormulaFromBase({
      variables: {
        baseFormulaId: formula.value,
        formulaMaterials: newFormulaMaterials,
      }
    }).then(({
      data: {
        createConcreteFormulaFromBase: {
          id: concreteFormulaId,
          code: concreteFormulaCode,
        }
      }
    }) => {
      snackbarContext.setNotificationTimeOut(
        Color.success,
        "Traço de reaproveitamento de carga criado com sucesso."
      );
      setFormula({
        value: concreteFormulaId,
        code: concreteFormulaCode,
      });
      setSendRequest(true);
    }).catch((error) => {
      snackbarContext.setNotificationTimeOut(
        Color.danger,
        `Erro ao criar traço de reaproveitamento de carga. ${error.message}`
      );
    });
  };

  const handleChangeReturnedQuantity = (value) => {
    setReturnedQuantity(value);
  };

  useEffect(() => {
    setAllWeightsValid(newFormulaMaterials.every(({ weight }) => weight >= 0));
  }, [newFormulaMaterials]);

  useEffect(() => {
    setEnableStart(
      selectedShipment
      && formula
      && quantity > 0
      && allWeightsValid
      && returnedQuantity > 0
    );
  }, [selectedShipment, formula, quantity, allWeightsValid, returnedQuantity]);

  useEffect(() => {
    if (sendRequest) {
      toggleConfirmModal();
      setSendRequest(false);
    }
  }, [sendRequest, toggleConfirmModal]);

  useEffect(() => {
    setReturnedQuantity(0.5);
  }, []);

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
          setControlMenuOpen("control_menu");
        }}
        size="lg"
        zIndex={2060}
      >
        <div className="modal-header justify-content-center" style={{ display: "block", border: "none" }}>
          <div style={{
            display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center",
          }}
          >
            <UpperTitle>Reaproveitamento de Carga</UpperTitle>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={setIsOpen}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
          </div>
        </div>
        { !loading && shipments.length > 0 ? (
          <Box>
            <Col>
              <Row
                noGutters
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Title>Carregamento retornado</Title>
                <TooltipComponent
                  tooltipTexts={[
                    "Selecione um carregamento realizado nas últimas 24 horas para reaproveitar o concreto retornado."
                  ]}
                />
              </Row>
              <Row noGutters>
                <Col lg={6}>
                  <Row
                    noGutters
                    style={{
                      height: "100%",
                      alignItems: "center"
                    }}
                  >
                    {!loading && shipments.length > 0 && (
                      <ShipmentSelect
                        shipments={shipments}
                        setSelectedShipmentId={setSelectedShipmentId}
                      />
                    )}
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row
                    noGutters
                    style={{
                      height: "100%",
                      alignItems: "center",
                      paddingBottom: "12px"
                    }}
                  >
                    <CustomLoadSlider
                      value={returnedQuantity}
                      setQuantity={handleChangeReturnedQuantity}
                      maxQuantity={selectedShipment ? selectedShipment.quantity : 0}
                      defaultQuantity={0.1}
                      lightBackground
                      title="Quantidade Retornada"
                      tooltipTexts={["Informe o volume retornado da carga anterior (quantidade de concreto no caminhão)"]}
                    />
                  </Row>
                </Col>

              </Row>
            </Col>
          </Box>
        )
          : ( 
            <Row
              noGutters
              style={{
                justifyContent: "center",
                margin: "0 15px 30px 15px"
              }}
            >
              {
                loading ? (
                  <Title
                    style={{
                      textAlign: "center"
                    }}
                  >
                    Buscando carregamentos disponíveis
                  </Title>
                )
                  : (
                    <React.Fragment>
                      <Title
                        style={{
                          textAlign: "center"
                        }}
                      >
                        Não existem carregamentos disponíveis para reaproveitamento.
                      </Title>
                      <Subtitle>
                        {`O tempo máximo permitido para reaproveitar uma carga é de ${maxTimeElapsed} horas`}
                      </Subtitle>
                    </React.Fragment>
                  )
              }
            </Row>
          )
        }

        {selectedShipment && (
          <Row noGutters>
            <Box style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "0 15px 30px 15px"
            }}
            >
              <Col
                lg={6}
                style={{
                  borderRight: !isMobile ? "2px solid #fff" : "none"
                }}
              >
                <ModalCard>
                  <DeliveryInfo shipment={selectedShipment} />
                </ModalCard>
              </Col>
              <Col lg={6}>
                <ModalCard>
                  <ContractInfo shipment={selectedShipment} />
                </ModalCard>
              </Col>
              <Col
                lg={6}
                style={{
                  borderRight: !isMobile ? "2px solid #fff" : "none"
                }}
              >
                <ModalCard noBottomMargin={!isMobile ? true : undefined}>
                  <FormulaInfo shipment={selectedShipment} />
                </ModalCard>
              </Col>
              <Col lg={6}>
                <ModalCard noBottomMargin>
                  <TimelineInfo shipment={selectedShipment} />
                </ModalCard>
              </Col>
            </Box>
          </Row>
        )}

        {selectedShipment && (
          <Box>
            <Col>
              <Row
                noGutters
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Title>Traço de Complemento</Title>
                <TooltipComponent
                  tooltipTexts={[
                    "Selecione o traço que irá redosar (complementar) o carregamento"
                  ]}
                />
              </Row>
              <Row noGutters>
                <Col lg={6}>
                  <Row
                    noGutters
                    style={{
                      height: "100%",
                      alignItems: "center"
                    }}
                  >
                    {!loading && shipments.length > 0 && (
                      <FormulaSelect
                        value={formula}
                        onChange={handleFormulaSelect}
                        width="100%"
                        padding="10px"
                      />
                    )}
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row
                    noGutters
                    style={{
                      height: "100%",
                      alignItems: "center",
                      paddingBottom: "12px"
                    }}
                  >
                    <CustomLoadSlider
                      value={quantity}
                      setQuantity={handleChangeQuantity}
                      minQuantity={parseFloat(returnedQuantity)}
                      defaultQuantity={returnedQuantity}
                      lightBackground
                      updateDefault
                      title="Quantidade Final"
                      tooltipTexts={["Informe o volume final desejado após a redosagem (volume total que irá carregar)"]}
                    />
                  </Row>
                </Col>
  
              </Row>
            </Col>
          </Box>
        )}

        {formula && (
          <div
            style={{
              padding: "14px",
            }}
          >
            <MaterialTable
              inventoryItemsData={inventoryItemsData}
              formula={formula}
              selectedShipment={selectedShipment}
              returnedQuantity={returnedQuantity}
              quantity={quantity}
              setNewFormulaMaterials={setNewFormulaMaterials}
            />
          </div>
        )}

        {(selectedShipment && returnedQuantity <= 0) && (
          <Card style={{ fontWeight: 700, paddingLeft: 20 }}>
            <Subtitle>
              O volume retornado deve ser maior que zero.
            </Subtitle>
          </Card>
        )}

        {(formula && quantity <= 0) && (
          <Card style={{ fontWeight: 700, paddingLeft: 20 }}>
            <Subtitle>
              A quantidade a carregar deve ser maior que zero.
            </Subtitle>
          </Card>
        )}

        {(formula && newFormulaMaterials.length > 0 && !allWeightsValid) && (
          <Card style={{ fontWeight: 700, paddingLeft: 20 }}>
            <Subtitle>
              A quantidade corrigida de todos os materiais deve ser maior ou igual a zero.
            </Subtitle>
          </Card>
        )}

        <Button
          color="info"
          onClick={handleCreateReturnedShipmentFormula}
          style={{
            width: "100%",
            margin: "0",
            padding: "17px 16px",
            borderRadius: "0 0 10px 10px"
          }}
          disabled={!enableStart}
        >
          Iniciar Reaproveitamento
        </Button>
      </Modal>
    </React.Fragment>
  );
};

export default compose(
  graphql(CREATE_CONCRETE_FORMULA_FROM_BASE_MUTATION, { name: "createConcreteFormulaFromBase" }),
  withSnackbarConsumer,
)(ReturnedShipmentModal);
