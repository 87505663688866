import gql from "graphql-tag";

export default gql`
  mutation ResolveManualOperation(
    $manualOperationId: Int!,
    $resolveManualOperationParams: ResolveManualOperationParams!
  ){
    resolveManualOperation(
      id: $manualOperationId,
      params: $resolveManualOperationParams
    ) {
      id
    }
  }
`;
