import gql from "graphql-tag";

export default gql`
  mutation FinishLoadingManualShipment(
      $concreteShipmentId: Int!,
      $resolveManualOperationParams: ResolveManualOperationParams!
  ){
    finishLoadingManualShipment(
      concreteShipmentId: $concreteShipmentId,
      resolveManualOperationParams: $resolveManualOperationParams
    ) {
      id
    }
  }
`;
