import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";

import { withSnackbarConsumer, Color } from "../../../contexts/SnackbarContext/SnackbarContext.jsx";
import LoadSlider from "../../../components/LoadSlider/LoadSlider";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import ComplementModal from "../ShipmentModal/ComplementModal";
import ReturnedShipmentModal from "../ShipmentModal/ReturnedShipmentModal";
import {
  Overlay,
  SharedStyle,
  Form,
  Icon,
  IconContainer,
  WhiteTitle,
  ButtonContainer,
  Hr
} from "../../../components/StyledComponents";
import Select from "./FormulaSelect";

const TRANSITION_EFFECT = "all 0.3s ease-in";
const CONFIRM_MODAL = "confirm_modal";
const CONTROL_MENU = "control_menu";
const COMPLEMENT_MODAL = "complement_modal";
const RETURNED_SHIPMENT_MODAL = "returned_shipment_modal";

const MenuContainer = styled.div`
  top: 55px;
  right: -5px;
  position: fixed;
  height: 300px;
  width: 400px;
  z-index: 2011;

  transition: ${TRANSITION_EFFECT};
  transform: translate(${({ open, show }) => (show ? (open ? "0%" : "calc(100% - 50px)") : "calc(100% + 10px)")});
`;

const Menu = styled.div`
  ${SharedStyle}
  top: 0px;
  right: 0px;
  position: absolute;
  display: inline-block;
  width: 350px;
`;

const ControlMenu = ({
  handleControlMenuOpen,
  show,
  snackbarContext,
  onRefetch,
  inventoryItemsData,
}) => {
  const [open, setOpen] = useState(null);
  const [quantity, setQuantity] = useState(5);
  const [formula, setFormula] = useState(null);
  const [complementModalOpen, setComplementModalOpen] = useState(false);
  const [returnedShipmentModalOpen, setReturnedShipmentModalOpen] = useState(false);
  const [modalData, setModalData] = useState(
    {
      formulaCode: formula && formula.code,
      operatorsSelected: [],
      teamSelected: []
    }
  );
  const [returnedQuantity, setReturnedQuantity] = useState(null);
  const [parentId, setParentId] = useState(null);

  const toggle = () => {
    handleControlMenuOpen(open !== CONTROL_MENU);
    setOpen(open !== CONTROL_MENU ? CONTROL_MENU : null);
  };

  const toggleConfirmModal = () => {
    handleControlMenuOpen(false);
    if (open === CONFIRM_MODAL) {
      setOpen(null);
      setFormula(null);
      setQuantity(5);
      setComplementModalOpen(false);
      setReturnedShipmentModalOpen(false);
    } else {
      setOpen(CONFIRM_MODAL);
      setComplementModalOpen(false);
      setReturnedShipmentModalOpen(false);
    }
  };

  const startShipment = () => {
    if (formula !== null) {
      toggleConfirmModal();
    } else {
      snackbarContext.setNotificationTimeOut(
        Color.warning,
        "Para iniciar um carregamento, é necessário selecionar uma fórmula."
      );
    }
  };

  const onChangeSlider = (value) => setQuantity(value);

  const onChangeSelect = (value) => setFormula(value);

  const toggleComplementModal = (state) => {
    if (open === COMPLEMENT_MODAL) {
      handleControlMenuOpen(true);
      setComplementModalOpen(false);
      setOpen(null);
    } else {
      setComplementModalOpen(state);
      handleControlMenuOpen(open !== CONTROL_MENU);
      setOpen(COMPLEMENT_MODAL);
    }
  };

  const toggleReturnedShipmentModal = (state) => {
    if (open === RETURNED_SHIPMENT_MODAL) {
      handleControlMenuOpen(true);
      setReturnedShipmentModalOpen(false);
      setOpen(null);
    } else {
      setReturnedShipmentModalOpen(state);
      handleControlMenuOpen(open !== CONTROL_MENU);
      setOpen(RETURNED_SHIPMENT_MODAL);
    }
  };

  const isControlMenuOpen = open === CONTROL_MENU;

  const isConfirmModalOpen = open === CONFIRM_MODAL;

  useEffect(() => {
    if (!open) {
      handleControlMenuOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setModalData({
      formulaCode: formula && formula.code,
      operatorsSelected: [],
      teamSelected: []
    });
  }, [formula]);

  return (
    <React.Fragment>
      <MenuContainer open={isControlMenuOpen} show={show}>
        <IconContainer onClick={() => toggle()}>
          <Icon className={`fa fa-arrow-${isControlMenuOpen ? "right" : "left"}`} />
        </IconContainer>

        <Menu>
          <WhiteTitle>MENU DE CONTROLE</WhiteTitle>

          <Hr />

          <Form>
            <Select
              value={formula}
              onChange={onChangeSelect}
            />

            <LoadSlider value={quantity} onChange={onChangeSlider} lightText />

            <ButtonContainer>
              <Button color="info" onClick={() => startShipment()} className="btn-block">
                Iniciar carregamento
              </Button>
            </ButtonContainer>

          </Form>

          <Hr />
          <ButtonContainer>
            <Button color="info" onClick={() => toggleReturnedShipmentModal(true)} className="btn-block">
              Reaproveitamento
            </Button>
          </ButtonContainer>

          <ButtonContainer
            style={{
              paddingBottom: "20px"
            }}
          >
            <Button color="info" onClick={() => toggleComplementModal(true)} className="btn-block">
              Redosagem
            </Button>
          </ButtonContainer>

        </Menu>
      </MenuContainer>

      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          toggleModal={toggleConfirmModal}
          data={modalData}
          quantityValue={quantity}
          formulaCode={formula.code}
          concreteFormulaId={formula.value}
          onRefetch={onRefetch}
          returnedQuantity={returnedQuantity}
          parentId={parentId}
        />
      )}

      {complementModalOpen && (
        <ComplementModal
          isOpen={complementModalOpen}
          setIsOpen={toggleComplementModal}
          setControlMenuOpen={setOpen}
          inventoryItemsData={inventoryItemsData}
        />
      )}

      {returnedShipmentModalOpen && (
        <ReturnedShipmentModal
          isOpen={returnedShipmentModalOpen}
          setIsOpen={toggleReturnedShipmentModal}
          setControlMenuOpen={setOpen}
          inventoryItemsData={inventoryItemsData}
          quantity={quantity}
          setQuantity={setQuantity}
          formula={formula}
          setFormula={setFormula}
          toggleConfirmModal={toggleConfirmModal}
          setReturnedQuantity={setReturnedQuantity}
          returnedQuantity={returnedQuantity}
          setParentId={setParentId}
        />
      )}

      <Overlay
        open={open}
        show={show}
        style={{
          zIndex: open === CONTROL_MENU ? 2010 : 2030
        }}
      />
    </React.Fragment>
  );
};

export default withSnackbarConsumer(ControlMenu);
