import React from "react";
import { Subscription } from "react-apollo";
import styled from "styled-components";
import ManualOperationAlert from "./ManualOperationAlert";
import ManualOperationModal from "./ManualOperationModal";
import SUBSCRIBE_TO_MANUAL_OPERATION from "../graphql/subscriptions/subscribeToManualOperation";
import SUBSCRIBE_TO_CONTROLLER_FINISHED from "../graphql/subscriptions/subscribeToControllerFinished";

const ManualOperationContainer = styled.div`
  top: 55px;
  left: 80px;
  position: fixed;
  z-index: 1029;
  height: 0px;

  @media screen and (max-width: 991px) {
    left: 0px;
  }
`;

class ManualOperation extends React.Component {
  state = {
    nextOperation: null,
  }

  componentWillReceiveProps (nextProps) {
    const {
      controllerHasFinished,
    } = nextProps;

    const loadingManualShipmentOperations = this.getLoadingManualShipmentOperations();
    const hasManualOperation = !!loadingManualShipmentOperations.length;

    if (controllerHasFinished && hasManualOperation) {
      this.onResolve(loadingManualShipmentOperations[0])();
    } else {
      this.clearOperation();
    }
  }

  onResolve = nextOperation => () => this.setState({ nextOperation });

  onSubscriptionData = () => this.props.refetchConcreteShipmentQuery();

  clearOperation = () => this.setState({ nextOperation: null });

  renderManualOperationAlert = (message, operations, stack) => {
    const amount = operations.length;

    return (
      <ManualOperationAlert
        message={message}
        amount={amount}
        stack={stack}
        nextOperation={amount > 0 && operations[0]}
        onResolve={this.onResolve}
      />
    );
  }

  getSimpleManualOperations = () => {
    const { manualOperations } = this.props;

    return (manualOperations || []).filter(operation => !operation.concreteShipment);
  }

  getLoadingManualShipmentOperations = () => {
    const { manualOperations } = this.props;

    return (manualOperations || []).filter(operation => !!operation.concreteShipment);
  }

  render() {
    const {
      controllerHasFinished,
      concreteShipmentId,
      refetchConcreteShipmentQuery,
    } = this.props;
    const { nextOperation } = this.state;
    const simpleManualOperations = this.getSimpleManualOperations();
    const loadingManualShipmentOperations = this.getLoadingManualShipmentOperations();

    return (
      <React.Fragment>
        <ManualOperationContainer>
          {this.renderManualOperationAlert("Painel em modo manual", simpleManualOperations, true)}
          {this.renderManualOperationAlert("Movimento fora da automação", loadingManualShipmentOperations, false)}
        </ManualOperationContainer>

        <ManualOperationModal
          controllerHasFinished={controllerHasFinished}
          operation={nextOperation}
          clearOperation={this.clearOperation}
          onRefetch={refetchConcreteShipmentQuery}
        />

        <Subscription
          subscription={SUBSCRIBE_TO_MANUAL_OPERATION}
          onSubscriptionData={this.onSubscriptionData}
          fetchPolicy="no-cache"
        />

        <Subscription
          subscription={SUBSCRIBE_TO_CONTROLLER_FINISHED}
          variables={{
            concreteShipmentId,
          }}
          onSubscriptionData={this.onSubscriptionData}
          fetchPolicy="no-cache"
        />
      </React.Fragment>
    );
  }
}

export default ManualOperation;
