import React, { useEffect, useState, useCallback } from 'react';
import { useMutation } from "@apollo/client";
import { httpClient } from "settings/apolloClient";
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import styled from 'styled-components';
import {
  FINISH_ACTIVE_SEQUENCE_MUTATION,
  CHANGE_ACTIVE_SEQUENCE_CODE_MUTATION,
} from './graphql/scheduleMutations';
import AcceptChangeImg from "assets/img/accept-change.svg";
import RejectChangeImg from "assets/img/reject-change.svg";
import PencilImg from "assets/img/pencil-edit-button.svg";

const SequenceCard = styled(Card)`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #F4F6F8;
`;

const ActionButton = styled.button`
  background-color: ${props => props.backgroundColor};
  color: white;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  &:disabled {
    background-color: #B4B6BB;
    cursor: not-allowed;
    color: #8798AD;
  }

  &:focus {
    outline: 0;
  }
`;

const CodeInput = styled.input`
  width: 100%;
  border: 1px solid #B4B6BB;
  font-weight: 700;
  font-size: 18px;
  border-radius: 5px;
  background-color: transparent;
  padding: 2px;

  &:focus {
    outline: 0;
  }
`;

const EditButton = styled.button`
  border: none;
  pointer-events: auto;
  cursor: pointer;
  background-color: transparent;
  margin: 2px 0;
  
  &:focus {
    outline: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

const SequenceInfoHeader = ({
  code,
  refetchSequence,
  canChangeCode,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newCode, setNewCode] = useState(code);

  const [changeActiveSequenceCode] = useMutation(CHANGE_ACTIVE_SEQUENCE_CODE_MUTATION, { client: httpClient });

  const handleChangeCode = (e) => {
    setNewCode(e.target.value);
  };

  const handleRejectChange = () => {
    setNewCode(code);
    setIsEditing(false);
  };

  const handleAcceptChange = useCallback(() => {
    changeActiveSequenceCode({
      variables: {
        code: newCode,
      },
    }).then(() => {
      setIsEditing(false);
      refetchSequence();
    });
  }, [changeActiveSequenceCode, newCode, refetchSequence]);

  useEffect(() => {
    setNewCode(code);
  }, [code]);

  return (
    <React.Fragment>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Col sm={12} md={12} lg={12}>
          <p
            style={{
              margin: "auto",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Programação
          </p>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <Col sm={12} md={12} lg={canChangeCode ? 10 : 12}>
          {
            !isEditing ? (
              <p
                style={{
                  margin: "auto",
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                {`#${code}`}
              </p>
            ) : (
              <CodeInput type="text" value={newCode} onChange={(e) => handleChangeCode(e)} />
            )
          }
        </Col>
        {
          canChangeCode && (
            <Col sm={12} md={12} lg={2}>
              {
                !isEditing ? (
                  <EditButton onClick={() => setIsEditing(true)} title="Editar código">
                    <img src={PencilImg} alt="edit" />
                  </EditButton>
                ) : (
                  <React.Fragment>
                    <EditButton onClick={() => handleAcceptChange()} title="Aceitar novo código">
                      <img src={AcceptChangeImg} alt="accept change" />
                    </EditButton>
                    <EditButton onClick={() => handleRejectChange()} title="Rejeitar novo código">
                      <img src={RejectChangeImg} alt="reject change" />
                    </EditButton>
                  </React.Fragment>
                )
              }
            </Col>
          )
        }
      </Row>
    </React.Fragment>
  );
};

export function SequenceInfo({
  sequence,
  refetchSequence,
  setCreateShipmentModalOpen,
  setSequence,
}) {
  const {
    id,
    code,
    numberOfShipments,
    completedShipments,
  } = sequence;

  const [shipmentsFinished, setShipmentsFinished] = useState(0);
  const [totalShipments, setTotalShipments] = useState(0);

  const [finishActiveSequence] = useMutation(FINISH_ACTIVE_SEQUENCE_MUTATION, { client: httpClient });

  const handleFinishActiveSequence = useCallback(() => {
    finishActiveSequence().then(() => {
      setSequence(null);
      refetchSequence();
    });
  }, [finishActiveSequence, refetchSequence, setSequence]);

  useEffect(() => {
    setShipmentsFinished(completedShipments);
    setTotalShipments(numberOfShipments);
  }, [sequence]);

  return (
    <SequenceCard key={`${id}-${code}`}>
      <SequenceInfoHeader
        code={code}
        refetchSequence={refetchSequence}
        canChangeCode={totalShipments === 0}
      />
      <h5
        style={{
          padding: "2px 0",
        }}
      >
        PROGRESSO
      </h5>
      <h2
        style={{
          padding: "5px 0",
        }}
      >
        {`${shipmentsFinished} de ${totalShipments}`}
      </h2>
      <h5
        style={{
          padding: "2px 0",
        }}
      >
        Cargas realizadas
      </h5>
      <ActionButton
        backgroundColor="#247867"
        onClick={() => setCreateShipmentModalOpen(true)}
      >
        Adicionar carga na programação
      </ActionButton>
      <ActionButton
        backgroundColor="#637381"
        disabled={shipmentsFinished !== totalShipments}
        onClick={() => handleFinishActiveSequence()}
      >
        Finalizar programação
      </ActionButton>
    </SequenceCard>
  );
};
