import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { compose, graphql } from "react-apollo";
import {
  Modal,
  Input,
  ButtonGroup,
  Button,
  Card,
  Row,
  Col
} from "reactstrap";
import {
  TableContainer,
  Table,
  Header,
  Row as TableRow,
  Data
} from "../../../components/Table/tableComponents";
import { withSnackbarConsumer, Color } from "../../../contexts/SnackbarContext/SnackbarContext.jsx";
import {
  Title,
  UpperTitle,
  Subtitle,
  Box
} from "../../../components/StyledComponents";

import { httpClient } from "../../../settings/apolloClient";

import { CREATE_SHIPMENT_COMPLEMENT_MUTATION } from "./graphql/mutations/createShipmentComplementMutation";
import { AVAILABLE_SHIPMENTS_QUERY } from "./graphql/queries/availableShipmentsQuery";
import { ShipmentSelect } from "./ShipmentSelect";
import TooltipComponent from "../../../components/Tooltip/Tooltip";
import { useMobileDevice } from "../../../utils/getDeviceType";

const maxTimeElapsed = 3;

const AGGREGATE_TYPES = [
  "GRAVEL",
  "SAND",
];

const MaterialTableEdit = ({
  inventoryItemsData,
  selectedShipmentId,
  setComplementRequest,
  setEnableStart,
  shipment,
}) => {
  const [baseItems, setBaseItems] = useState([]);
  const [items, setItems] = useState([]);
  const [complement, setComplement] = useState([]);
  const [complementQuantityByItem, setComplementQuantityByItem] = useState({});
  const [selectedLocationByItem, setSelectedLocationByItem] = useState({});
  const [aggregateIds, setAggregateIds] = useState([]);

  const {
    inventoryItems
  } = inventoryItemsData || [];

  const isMobile = useMobileDevice();

  useEffect(() => {
    const newItems = inventoryItems.filter(
      ({ inventoryLocations }) => inventoryLocations.length > 0
    ).map(item => ({
      ...item,
      quantity: 0,
    }));
    setItems(newItems);
    setBaseItems(newItems);
  }, [inventoryItems]);

  const handleChangeInput = (itemId, value) => {
    setComplementQuantityByItem((prev) => {
      const newComplementQuantityByItem = { ...prev };
      newComplementQuantityByItem[itemId] = value;
      return newComplementQuantityByItem;
    });
  };

  useEffect(() => {
    if (!shipment) {
      return;
    }
    const {
      inventoryChanges
    } = shipment || [];
    const newItems = baseItems.map((item) => {
      const newItem = { ...item };
      const changes = inventoryChanges.filter(({
        inventoryItem: {
          id,
        },
      }) => id === item.id);
      newItem.quantity = changes.length > 0 ? changes.reduce((acc, {
        quantity,
      }) => acc + parseFloat(quantity), 0) : 0;
      return newItem;
    });
    setItems(newItems);
    setComplementQuantityByItem({});
  }, [shipment, baseItems]);

  useEffect(() => {
    if (baseItems.length > 0) {
      setSelectedLocationByItem(baseItems.reduce((acc, {
        id,
        inventoryLocations,
      }) => {
        const location = inventoryLocations[0];
        acc[id] = location.id;
        return acc;
      }, {}));
    }
  }, [baseItems]);

  useEffect(() => {
    if (items.length > 0) {
      setComplement(() => {
        let newComplement = [];
        newComplement = items.reduce((acc, {
          id: itemId,
        }, index) => {
          const locationId = selectedLocationByItem[itemId];
          const quantity = complementQuantityByItem[itemId] || 0;
          if (quantity === 0) {
            return acc;
          }
          acc.push({
            inventoryItemId: itemId,
            order: index + 1,
            shipmentMaterialLocations: [
              {
                inventoryLocationId: locationId,
                formulaWeight: quantity,
              },
            ],
          });
          return acc;
        }, []);
        return newComplement;
      });
    }
  }, [complementQuantityByItem, selectedLocationByItem, items]);

  useEffect(() => {
    setComplementRequest((prev) => {
      const newRequest = { ...prev };
      newRequest.shipmentMaterials = complement.filter(({ shipmentMaterialLocations }) => {
        if (shipmentMaterialLocations.length === 0 || shipmentMaterialLocations[0].quantity <= 0) {
          return false;
        }
        return true;
      });
      return newRequest;
    });
  }, [complement]);

  useEffect(() => {
    if (inventoryItems.length > 0) {
      const aggregateItems = inventoryItems.filter(({ materialType }) => AGGREGATE_TYPES.includes(materialType));
      setAggregateIds(aggregateItems.map(({ id }) => id));
    }
  }, [inventoryItems]);

  useEffect(() => {
    if (complement.length > 0) {
      // check if any aggregate item is selected, if there is at least one, enable start button
      const isAggregateSelected = complement.some(({ inventoryItemId }) => aggregateIds.includes(inventoryItemId));
      if (isAggregateSelected) {
        setEnableStart(true);
      } else {
        setEnableStart(false);
      }
    } else {
      setEnableStart(false);
    }
  }, [complement]);

  return (
    <div style={{
      padding: "1%",
      overflow: "auto",
      width: "98%",
      margin: "auto",
    }}
    >
      <TableContainer
        style={{
          border: "1px solid #f6f8ff"
        }}
      >
        <Table>
          <thead>
            <tr>
              <Header>Material</Header>
              <Header>Consumido na Carga</Header>
              { !isMobile && (
                <Header>Complemento</Header>
              )}
            </tr>
          </thead>
          <tbody>
            {items.map(({
              name: itemName,
              id: itemId,
              quantity,
              unit,
              inventoryLocations,
            }) => (
              <React.Fragment>
                <TableRow
                  key={itemId}
                  padding="10px"
                >
                  <Data style={{ fontWeight: 700 }}>{itemName}</Data>
                  <Data style={{ textAlign: "center", fontWeight: 600 }}>{`${parseFloat(quantity) * (-1)} ${unit}`}</Data>
                  { !isMobile && (
                    <Data style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <Input
                        disabled={!selectedShipmentId}
                        type="number"
                        onChange={(e) => handleChangeInput(itemId, e.target.value)}
                        value={complementQuantityByItem[itemId] || "0"}
                        style={{ textAlign: "right" }}
                        min="0"
                      />
                      <ButtonGroup style={{ width: "100%" }}>
                        { inventoryLocations.map(({ id, name }) => {
                          const isSelected = selectedLocationByItem[itemId] === id;
                          return (
                            <Button
                              disabled={inventoryLocations.length === 1}
                              color={isSelected ? "info" : "outline-info"}
                              onClick={() => setSelectedLocationByItem((prev) => {
                                const newSelectedLocationByItem = { ...prev };
                                newSelectedLocationByItem[itemId] = id;
                                return newSelectedLocationByItem;
                              })}
                              style={{
                                padding: "0.375rem 0.75rem",
                                width: "100%",
                              }}
                            >
                              {name}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </Data>
                  )}
                </TableRow>
                { isMobile && (
                  <TableRow
                    style={{
                      width: "100%"
                    }}
                  >
                    <Data style={{ paddingTop: 5, paddingBottom: 5 }}>
                      <Subtitle>Quantidade para redosar</Subtitle>
                    </Data>
                    <Data>
                      <Input
                        disabled={!selectedShipmentId}
                        type="number"
                        onChange={(e) => handleChangeInput(itemId, e.target.value)}
                        value={complementQuantityByItem[itemId] || "0"}
                        style={{ textAlign: "right" }}
                        min="0"
                      />
                      <ButtonGroup style={{ width: "100%" }}>
                        { inventoryLocations.map(({ id, name }) => {
                          const isSelected = selectedLocationByItem[itemId] === id;
                          return (
                            <Button
                              disabled={inventoryLocations.length === 1}
                              color={isSelected ? "info" : "outline-info"}
                              onClick={() => setSelectedLocationByItem((prev) => {
                                const newSelectedLocationByItem = { ...prev };
                                newSelectedLocationByItem[itemId] = id;
                                return newSelectedLocationByItem;
                              })}
                              style={{
                                padding: "0.375rem 0.75rem",
                                width: "100%",
                              }}
                            >
                              {name}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </Data>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

const ComplementModal = ({
  isOpen,
  setIsOpen,
  setControlMenuOpen,
  inventoryItemsData,
  snackbarContext,
  createShipmentComplement,
}) => {
  const [shipments, setShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedShipmentId, setSelectedShipmentId] = useState(null);
  const [complementRequest, setComplementRequest] = useState({
    concreteShipmentId: null,
    shipmentMaterials: [],
  });
  const [enableStart, setEnableStart] = useState(false);

  const {
    data: availableShipmentsData,
    loading,
  } = useQuery(AVAILABLE_SHIPMENTS_QUERY, {
    variables: {
      timeElapsed: maxTimeElapsed,
      shipmentType: "complement",
    },
    fetchPolicy: "network-only",
    client: httpClient,
  });

  useEffect(() => {
    if (availableShipmentsData) {
      setShipments(availableShipmentsData.availableConcreteShipments);
    }
  }, [availableShipmentsData]);

  useEffect(() => {
    if (selectedShipmentId) {
      const shipment = shipments.find(({ id }) => id === selectedShipmentId);
      setSelectedShipment(shipment);
      setComplementRequest({
        concreteShipmentId: selectedShipmentId,
        shipmentMaterials: [],
      });
    }
  }, [selectedShipmentId, shipments]);

  const handleCreateShipmentComplement = async () => {
    createShipmentComplement({
      variables: {
        concreteShipmentId: complementRequest.concreteShipmentId,
        shipmentMaterials: complementRequest.shipmentMaterials
      }
    }).then(() => {
      setIsOpen(false);
      setControlMenuOpen(null);
      snackbarContext.setNotificationTimeOut(
        Color.success,
        "Redosagem de carga criada com sucesso."
      );
    }).catch((error) => {
      snackbarContext.setNotificationTimeOut(
        Color.danger,
        `Erro ao criar redosagem de carga. ${error.message}`
      );
    });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
          setControlMenuOpen("control_menu");
        }}
        size="lg"
        zIndex={2060}
      >
        <div className="modal-header justify-content-center" style={{ display: "block", border: "none" }}>
          <div style={{
            display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center",
          }}
          >
            <UpperTitle>Redosagem de Carga</UpperTitle>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={setIsOpen}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
          </div>
          {/* <Subtitle>Selecione um carregamento que foi realizado nas últimas 3 horas para adicionar materiais de acordo com a necessidade observada.</Subtitle> */}
        </div>

        { !loading && shipments.length > 0 ? (
          <Box>
            <Col>
              <Row
                noGutters
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Title>Carregamento para redosar</Title>
                <TooltipComponent
                  tooltipTexts={[
                    "Selecione um carregamento realizado nas últimas 3 horas para para adicionar materiais de acordo com a necessidade observada."
                  ]}
                />
              </Row>
              <Row noGutters>
                <Col>
                  <Row
                    noGutters
                    style={{
                      height: "100%",
                      alignItems: "center"
                    }}
                  >
                    {!loading && shipments.length > 0 && (
                      <ShipmentSelect
                        shipments={shipments}
                        setSelectedShipmentId={setSelectedShipmentId}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Box>
        )
          : (
            <Row
              noGutters
              style={{
                justifyContent: "center",
                margin: "0 15px 30px 15px"
              }}
            >
              {
                loading ? (
                  <Title
                    style={{
                      textAlign: "center"
                    }}
                  >
                    Buscando carregamentos disponíveis
                  </Title>
                )
                  : (
                    <React.Fragment>
                      <Title
                        style={{
                          textAlign: "center"
                        }}
                      >
                        Não existem carregamentos disponíveis para redosagem.
                      </Title>
                      <Subtitle>
                        {`O tempo máximo permitido para redosar uma carga é de ${maxTimeElapsed} horas`}
                      </Subtitle>
                    </React.Fragment>
                  )
              }
            </Row>
          )
        }
        { selectedShipment && (
          <MaterialTableEdit
            shipment={selectedShipment}
            setComplementRequest={setComplementRequest}
            inventoryItemsData={inventoryItemsData}
            selectedShipmentId={selectedShipmentId}
            setEnableStart={setEnableStart}
          />
        )
        }

        {!enableStart && selectedShipmentId && (
          <Card style={{ fontWeight: 700, paddingLeft: 20 }}>
            <Subtitle>
              Necessário adicionar pelo menos um agregado para iniciar a redosagem.
            </Subtitle>
          </Card>
        )}

        <Button
          color="info"
          onClick={() => handleCreateShipmentComplement()}
          style={{
            width: "100%",
            margin: "0",
            padding: "17px 16px",
            borderRadius: "0 0 10px 10px"
          }}
          disabled={
            !selectedShipmentId
            || complementRequest.shipmentMaterials.length === 0
            || !enableStart
          }
        >
          Iniciar Redosagem
        </Button>
      </Modal>
    </React.Fragment>
  );
};

// export default withSnackbarConsumer(ComplementModal);

export default compose(
  graphql(CREATE_SHIPMENT_COMPLEMENT_MUTATION, { name: "createShipmentComplement" }),
  withSnackbarConsumer,
)(ComplementModal);
